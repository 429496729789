import React from "react";
import classNames from "classnames";
import { faPhone, faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./App.scss";
import map from "./map1.jpg";
import dveriPdf from "./Dveri.pdf";
import fasadyPdf from "./Fasady.pdf";
import logo from "./logo.jpg";

function importAll(r) {
  return r.keys().map(r);
}

const images1 = importAll(require.context("./images/1", false, /\.(jpg|png|jpeg)$/)).reverse();
const images2 = importAll(require.context("./images/2", false, /\.(jpg|png|jpeg)$/));
const images3 = importAll(require.context("./images/3", false, /\.(jpg|png|jpeg)$/));

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      section1: true,
      section2: false,
      section3: false,
    };

    this.firstSectionClick = this.firstSectionClick.bind(this);
    this.secondSectionClick = this.secondSectionClick.bind(this);
    this.thirdSectionClick = this.thirdSectionClick.bind(this);
  }

  firstSectionClick() {
    document.querySelector('#our-products').scrollIntoView();
    this.setState({section1: true, section2: false, section3: false});
  }

  secondSectionClick() {
    document.querySelector('#our-products').scrollIntoView();
    this.setState({section1: false, section2: true, section3: false});
  }

  thirdSectionClick() {
    document.querySelector('#our-products').scrollIntoView();
    this.setState({section1: false, section2: false, section3: true});
  }

  loadImages(images) {
    return images.map((image, index) => {
      return(
        <div className="col-12 col-md-4 col-lg-3" key={index}>
          <div className="product-image-wrapper d-flex align-items-center">
            <img src={image} alt="" className="product-image"/>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        <div id="top" />
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
          <div className="container">
            <a className="navbar-brand font-weight-bold" href="#top"><img src={logo} alt="" className="logo"/></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="#about-us">Про нас</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#schemes">Схеми фрезерування</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contacts">Контакти</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div id="about-us">
          <div className="container">
            <h2 className="title text-center mb-5 uppercase">Про нас</h2>
            <div className="text text-justify">
              <p>Фірма «Фабрика Фасадів» - це команда професіоналів відданих своїй справі.</p>
              <b>Основними напрямками діяльності є:</b>
              <ul>
                <li>
                  виготовлення меблевих фасадів з МДФ;
                </li>
                <li>
                  виготовлення декоративних накладок на металеві двері з МДФ;
                </li>
              </ul>
              <b>Ми проводимо супровід нашої продукції в:</b>
              <ul>
                <li>розробці дизайну виробів за індивідуальними замовленнями;</li>
                <li>консультативні послуги щодо проектування, розробки, виготовлення, монтажу виробів з елементами нашої продукції.</li>
              </ul>
              Відхилення від стандартних розмірів, малюнків, розробка індивідуального дизайну, консультативно-пошукові роботи надаються нашим клієнтам безкоштовно!
            </div>
          </div>
        </div>

        <div id="our-products" className="d-flex flex-column align-items-center">
          <div className="section d-flex justify-content-center flex-column flex-md-row">
            <button className={classNames("btn", "mr-3", { active: this.state.section1 })} type="button" onClick={this.firstSectionClick}>Фасади</button>
            <button className={classNames("btn", "mr-3", { active: this.state.section2 })} type="button" onClick={this.secondSectionClick}>Накладки</button>
            <button className={classNames("btn", { active: this.state.section3 })} type="button" onClick={this.thirdSectionClick}>Приліжкові стінки та дзеркала</button>
          </div>
          <div className="container-fluid">
            <div className="row">
              {this.state.section1 && this.loadImages(images1)}
              {this.state.section2 && this.loadImages(images2)}
              {this.state.section3 && this.loadImages(images3)}
            </div>
          </div>
        </div>

        <div id="schemes">
          <div className="container">
            <h2 className="title text-center mb-5 uppercase">Схеми фрезерування</h2>
            <div className="row">
              <div className="col-12 col-md-6 text-md-right text-center">
                <a href={dveriPdf} target="_blank" rel="noopener noreferrer">Двері(pdf)</a>
              </div>
              <div className="col-12 col-md-6 text-md-left text-center">
                <a href={fasadyPdf} target="_blank" rel="noopener noreferrer">Фасади(pdf)</a>
              </div>
            </div>
          </div>
        </div>

        <div id="contacts">
          <div className="container-fluid pl-md-0">
            <div className="row">
              <div className="col-12 p-0 col-lg-6 p-lg-auto">
                <img src={map} alt="" className="img-fluid"/>
              </div>
              <div className="col-12 col-lg-6 d-flex align-items-center">
                <div className="container mt-5 mb-5 mt-lg-0 mb-lg-0">
                  <div className="d-flex flex-column">
                    <h2 className="title mb-4">Телефонуйте або пишіть нам на електронну пошту:</h2>
                    <div className="phone mb-2">
                      <span className="icon pr-2">
                        <FontAwesomeIcon icon={faPhone} />
                      </span>
                      +380979196967
                    </div>
                    <div className="phone mb-2">
                      <span className="icon pr-2">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </span>
                      fabryka.fasadiv@gmail.com
                    </div>
                    <div className="phone d-flex align-items-center">
                      <span className="icon">
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                      </span>
                      <div className="pl-2">м. Івано-Франківськ<br/>с.Черніїв вул. Хриплинська 11</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="d-flex align-items-center">
          <div className="container">
            Фабрика фасадів © Copyright {new Date().getFullYear()}. All Rights Reserved.
          </div>
        </footer>
      </div>
    );
  }
}

export default App;

